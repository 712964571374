import { admin2 } from '@/api/instance'

//= > 取得下載中心任務列表
export const GetSheetExport = async ({
  shopId,
  start,
  limit,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/sheetExportTask`,
    params: {
      start,
      limit,
    },
  })

  return res
}

//= > 取得下載中心任務數量
export const GetSheetExportCount = async ({
  shopId,
  name,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/sheetExportTask/count`,
    params: {
      name,
    },
  })

  return res
}

// 取得下載中心任務
export const FindSheetExport = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/sheetExportTask/${id}`,
  })

  return res
}

// 取得下載中心任務下載連結
export const GetSheetExportDownloadLink = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/sheetExportTask/${id}/downloadLink`,
  })

  return res
}
